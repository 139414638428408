<template>
  <v-col align="center"
    cols="12"
    class="pl-2 pr-0"
    :md="size === 2 ? 3 : size === 3 ? 4 : undefined"
  >
    <v-hover
      v-slot="{ hover }"
      open-delay="200">
      <v-card class="mx-auto" flat outlined color="transparent">
        <v-col cols="12">
          <v-img
            :src="require(`@/assets/icon-button/Ver.1/${value.img}`)"
            :width="hover ? '60%' : '50%'"
            :height="hover ? '60%' : '50%'"
          ></v-img>
        </v-col>
        <v-col cols="12" v-if="!hover"></v-col>
        <!-- <v-expand-transition>
          <div
            v-if="hover"
            class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal text-h2 white--text"
            style="height: 100%;"
          >
            $14.99
          </div>
        </v-expand-transition> -->
        <v-card-text align="center" :class="[$vuetify.breakpoint.lgAndUp ? 'display-2' : 'display-1']" v-html="value.document_name">
          <!-- <span :class="[$vuetify.breakpoint.lgAndUp ? 'display-1' : 'display-1']">{{value.document_name}}</span> -->
        </v-card-text>
      </v-card>
    </v-hover>
  </v-col>
</template>

<script>
  export default {
    name: 'DocumentFeedCard',

    props: {
      size: {
        type: Number,
        required: true,
      },
      value: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
    }
  }
</script>

<style>
.v-image__image {
  transition: .3s linear;
}
.bg-custom {
  background: url('../assets/articles/1.2.1600x350.jpg');
  background-size: cover;
}
</style>
 
